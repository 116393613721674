@import "src/styles";

.container {
  width: 100%;
  height: 100%;
  position: relative;

  img,
  canvas {
    position: absolute;
    top: 0;
    left: 0;
  }

  canvas {
    z-index: 1;
  }
}
