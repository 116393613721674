@import 'src/styles';
@import 'core/styles/animations/_fadeIn.scss';

.wrap {
  padding: 20px;
  max-width: 500px;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: $terminateBg;
  color: $terminateTextColor;
  position: relative;

  .survey {
    position: absolute;
    top: 0;
    left: 0;
  }

  .top {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .titles {
      width: calc(100% - 48px);
      max-width: 263px;
      text-align: center;

      &_appname {
        color: $terminateTitleColor;
        font-size: $fontSize26;
      }

      &_title {
        font-size: $fontSize22;
        line-height: 1.45;
        letter-spacing: rem(-0.57);
        margin-top: 16px;
      }

      &_text {
        font-size: $fontSize16;
        line-height: 1.38;
        letter-spacing: rem(-0.41);
        margin-top: 10px;
      }
    }
  }

  .form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10.84vh;
    width: 100%;
    height: 55px;

    &_field {
      width: calc(100% - 94px);
      height: 100%;
      position: relative;

      &_error_text {
        position: absolute;
        left: 0;
        top: 100%;
        margin-top: 5px;
        font-size: $fontSize12;
        letter-spacing: rem(-0.57);
        color: $colorError;
        margin-left: 15px;
      }
    }

    &_input {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      background: $terminateInputBg;
      border: 1px solid var(--primaryColor);
      padding: 0 15px;
      font-weight: $fwb;
      color: $terminateInputColor;

      &__invalid {
        border-color: $colorError;
      }

      &::placeholder {
        font-weight: $fwn;
      }
    }

    &_button {
      width: 85px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      background-color: var(--primaryColor);

      &:disabled {
        opacity: 0.5;
      }

      &_title {
        color: $white;
        font-size: $fontSize16;
        letter-spacing: rem(-0.57);
        font-weight: $fwb;
      }

      &_check {
        width: 20px;
        height: 15px;
        opacity: 0;
        @include animation('fadeIn .3s forwards');
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}