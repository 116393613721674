@import "src/styles/index";

.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 0.9fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 10px 20px;
  padding-bottom: 5px;

  .block {
    display: flex;
    align-items: flex-start;
    opacity: 0.2;

    .title {
      font-size: $fontSize12;
      text-align: left;
      color: $mineShaft;
      font-weight: $fwn;
    }

    .icon {
      background-image: url(../../../icons/check-min.svg);
      background-size: contain;
      width: 24px;
      height: 20px;
    }

    &.active {
      animation: fadeInItem ease 2s;
      opacity: 1;
    }
  }
}

@keyframes fadeInItem {
  0% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}