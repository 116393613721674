@import "src/styles";

.wrapper {
  margin-bottom: 32px;

  h2 {
    margin-bottom: 16px;
  }

  .warning {
    width: 100%;
    text-align: center;
    margin-bottom: 16px;
    color: $plansBadgeColor;
    font-weight: 600;
    line-height: $fontSize22;
  }

  .features {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;

    .item {
      width: 100%;
      padding: 12px 15px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 5px;
      border: 2px solid var(--primaryColorLight);

      .icon {
        width: 24px;
        height: 24px;
        padding: 4px;
        border-radius: 12px;
        margin-right: 10px;
        background-color: var(--primaryColorLight);

        path {
          stroke: $primaryColor;
        }
      }

      .title {
        text-align: start;
        width: 100%;
        font-size: $fontSize20;
        margin-bottom: 4px;
      }

      .subtitle {
        width: 100%;
        text-align: start;
        font-size: $fontSize13;
        color: $manageMainColor;
        opacity: 0.7;
      }
    }
  }
}
