@import "src/styles";

@mixin text {
  margin-top: 10px;
  font-size: $fontSize14;
  font-weight: $fwn;
}

.tile {
  background-color: $white;
  max-width: 107px;
  height: 165px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 25px 5px;

  .timer_hat {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    border-radius: 15px 15px 0px 0px;
    background: #C8C8C8;
    width: 100%;
    height: 40px;

    .discount {
      font-size: $fontSize12;
      font-weight: $fwb;
      color: $white;
      text-transform: uppercase;
      text-align: center;
    }

    .time_text {
      font-size: $fontSize12;
      font-weight: $fwn;
      color: $white;
    }
  }

  &.isActiveTile {
    border: 2px solid var(--primaryColor);
    background-color: #DEEBFF;
    height: 180px;

    .discount,
    .timer_hat {
      width: 101%;
      background: var(--primaryColor);
      border-radius: 11px 11px 0px 0px;
    }
  }

  .actual_price {
    font-size: $fontSize20;
    font-weight: $fwstb;
    margin-top: 20px;
  }

  .textLineThrough {
    @include text;
    text-decoration: line-through;
  }

  .title {
    @include text;
  }

  .subTitle {
    text-align: center;
    font-size: $fontSize10;
    font-weight: $fwn;
    margin-top: 10px;

    b {
      font-size: $fontSize14;
      font-weight: $fwb;
      color: var(--primaryColor);
    }
  }

  .bottonText {
    font-size: $fontSize10;
    font-weight: $fwn;
    margin-top: 5px;
  }
}