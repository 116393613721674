@import "src/styles/index";

.full_mode {
  .full_title {
    color: var(--secondaryText);

    &:last-child {
      color: var(--primaryText);
    }
  }
}

.progress {
  &__wrapper {
    width: 100%;
    min-height: 140px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    .progress_line_wrap {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;

      .progress__number {
        font-style: normal;
        font-weight: 700;
        font-size: $fontSize20;
        line-height: $fontSize28;
        margin-bottom: 15px;
        z-index: 1;
        color: var(--primaryColor);
      }

      &.in_row {
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;

        .progress__number {
          margin-top: 12px;
          margin-left: 10px;
        }
      }

      .line {
        width: 90%;
        max-width: 285px;
        position: relative;
        overflow: hidden;
        border-radius: 50px;

        .inactive {
          width: 100%;
          height: 12px;
          background-color: var(--primaryColor);
          opacity: 0.2;
          border-radius: 50px;
        }

        .active {
          transition: 0.5s width ease;
          width: 90%;
          height: 12px;
          position: absolute;
          top: 0;
          left: 0;
          background-color: var(--primaryColor);
          border-radius: 50px;

          &.animated {
            width: 25%;
            animation: infiniteLoader linear 1.5s;
            animation-iteration-count: infinite;
          }
        }
      }
    }
  }
}

@keyframes infiniteLoader {
  0% {
    left: -30%;
  }

  50% {
    left: 110%;
  }

  100% {
    left: -30%;
  }
}