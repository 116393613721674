@import "src/styles";

.progress-bar {
  background-color: transparent;
  display: flex;
  justify-content: center;
  min-height: 75px;
}

.progress {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: unset;
  max-width: 345px;
  padding-top: 16px;
  position: absolute;
  z-index: 2;
  min-height: 38px;

  .container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .back_btn_wrap {
      width: 30%;
      transition: all ease 100s;
      transition-delay: $pageTransitionDelay;

      .back_btn {
        padding: 0;
        width: 43px;
        height: 21px;
        text-align: left;
        background-size: 21px 13px;

        .icon {
          path {
            fill: $backArrowButtonColor;
          }
        }
      }
    }
  }

  .right_part {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 30%;
    transition: all ease 100s;
    transition-delay: $pageTransitionDelay;

    .skip_btn {
      color: var(--primaryColor);
      font-size: $fontSize16;
      font-weight: $fwstb;
      padding: 5px;
    }
  }

  &.onlyLogo {
    .container {
      justify-content: center;
    }
  }

  &.hidden_back_button {
    .back_btn_wrap {
      .back_btn {
        background: $mainBgColor;
        pointer-events: none;
      }
    }
  }

  &.hidden_Progress {

    .counter,
    .line {
      opacity: 0;
      transition: none;
    }
  }

  &__title {
    font-size: $fontSize22;
    font-weight: $fwb;
    letter-spacing: rem(-0.02);
    text-align: left;
    color: $mineShaft;
  }

  .counter {
    font-size: $fontSize16;
    letter-spacing: rem(-0.02);
    text-align: right;
    color: $stepsCounterColor;
    width: 43px;
    transition: all ease $pageTransitionDuration;
    transition-delay: $pageTransitionDelay;
  }

  .line {
    margin: 15px 0 0;
    transition: all ease $pageTransitionDuration;
    transition-delay: $pageTransitionDelay;
  }
}