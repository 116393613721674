@import "src/styles";

@mixin circle {
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.wrapper {
  margin-top: -60px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  .container {
    width: 100%;
    height: 80%;
    position: relative;

    img,
    canvas {
      position: absolute;
      top: 0;
      left: 0;
    }

    canvas {
      z-index: 1;
    }

    .point {
      border-radius: 3px;
      background: white;
      position: absolute;
      width: 6px;
      height: 6px;
      animation-name: fadeInTitle;
      animation-duration: 800ms;
      animation-timing-function: ease-in;
      box-sizing: border-box;
    }
  }

  .photo {
    background: #d8d8d8;
    margin: auto;
  }

  .footer {
    width: 100%;
    flex-basis: 20%;
    flex-grow: 0;
    position: absolute;
    bottom: 0;
    z-index: 3;
    background: var(--mainBg);
  }

  .check_mark {
    display: none;
    animation: fadeInCheckMark 1s ease-in;
    position: relative;

    .check_mark_first {
      width: 95px;
      height: 95px;
      background-color: var(--secondaryBg);
      opacity: 1;
      align-items: center;
      justify-content: center;
      display: flex;
      @include circle;

      path {
        stroke: var(--primaryColor);
      }
    }

    .check_mark_second {
      width: 125px;
      height: 125px;
      background-color: var(--primaryColor);
      opacity: 0.1;
      @include circle;
    }

    .check_mark_third {
      width: 155px;
      height: 155px;
      background-color: var(--primaryColor);
      opacity: 0.2;
      @include circle;
    }
  }

  &.full_mode:not(.finished) {
    --primaryColor: white;
    .footer {
      background: linear-gradient(180deg, transparent 0%, #000a 4%);
    }
  }

  &.full_mode {
    .footer {
      padding-top: 8px;
    }

    .container {
      height: 100%;

      &.transformed {
        transform: translateY(-25%);
      }

      img {
        height: calc(100% + 60px);
      }
    }
  }

  &.finished {
    justify-content: center;

    .container {
      display: none;
    }

    .footer {
      background: transparent;
    }

    .check_mark {
      display: flex;
    }
  }
}

@keyframes fadeInCheckMark {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
