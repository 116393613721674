@import 'src/styles';

.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  user-select: none;

  .label {
    display: flex;
    align-items: center;
    font-size: $fontSize16;
    line-height: $fontSize24;
    color: $manageMainColor;
    width: 100%;

    &.checked {
      font-weight: 600;
      color: $manageAccentColor;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkbox__wrapper {
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }

    .checkbox__mark {
      height: 25px;
      width: 25px;
      background: $manageCheckboxBg;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: $transition;

      &::after {
        content: "";
        position: absolute;
        display: none;
        width: 19px;
        height: 19px;
        background: $manageCheckmark;
        -webkit-mask-image: url(./icons/checkmark.svg);
        mask-image: url(./icons/checkmark.svg);
      }
    }
  }

  input:checked~.checkbox__mark {
    background-color: $manageCheckboxChecked;
  }

  input:checked~.checkbox__mark::after {
    display: block;
  }
}