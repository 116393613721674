@import "src/styles";

.wrap {
  padding: 16px;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin-bottom: 32px;
  }

  .btn {
    margin-bottom: 16px;
  }
}

.back_button {
  position: absolute;
  top: 8px;
  left: 0px;
  padding: 10px;
  z-index: 10;

  svg {
    path {
      fill: $manageCheckmark;
    }
  }
}
