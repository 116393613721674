@import 'src/styles';

.header {
  width: 100%;
  padding: 0 16px;
  position: relative;

  .title {
    font-family: $managePrimaryFontFamily;
    font-style: normal;
    font-weight: 700;
    font-size: $fontSize24;
    line-height: $fontSize28;
    margin-bottom: 7px;
    color: $manageTitleColor;
  }

  .subtitle {
    font-family: $managePrimaryFontFamily;
    font-style: normal;
    font-weight: 700;
    font-size: $fontSize20;
    line-height: $fontSize24;
    margin-bottom: 5px;
    color: $manageTitleColor;
  }

  .text {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: $fontSize15;
    line-height: $fontSize20;
    color: $manageMainColor;
    letter-spacing: normal;
  }

  .back_button {
    position: absolute;
    top: -6px;
    left: 0px;
    padding: 10px;
    z-index: 1;

    svg {
      path {
        fill: $manageBackButtonColor;
      }
    }
  }
}