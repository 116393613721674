.wrapper {
  background: #ffc439;
  border-radius: 4px;
  position: relative;

  .spinner {
    position: absolute;
    height: 100%;
    background: none;

    >* {
      scale: 0.75;
    }
  }
}