@import "src/styles";

.root {
  margin-top: -60px;
  display: flex;
  width: 100vw;
  flex-direction: column;
  flex-basis: 100%;
  position: relative;
  overflow: hidden;
  background: $mineShaft;
}

.loading_text {
  position: absolute;
  left: 50%;
  bottom: 20%;
  transform: translateX(-50%);
  z-index: 100000;
  color: $white;
  width: 100%;
  text-align: center;
  font-size: $fontSize16;
  font-style: normal;
  font-weight: 700;
  line-height: $fontSize24;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.top_animations {
  display: flex;
  justify-content: space-evenly;
  width: 100vw;
  z-index: 2;
  position: absolute;
  top: 20px;
}

.heart_rate {
  margin: 0 20px;

  p {
    color: $white;
    text-align: left;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.20);
    font-family: $fontFamily;
    font-size: $fontSize12;
    font-style: normal;
    font-weight: 600;
    line-height: $fontSize20;
  }
}

.bottom_animations {
  display: flex;
  align-items: center;
  width: 100vw;
  z-index: 2;
  max-width: 375px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  bottom: 20px;
}

.bg {
  pointer-events: none;
  width: 100vw;
  height: 100%;
  z-index: 1;
  background: linear-gradient(180deg, #2D2D2D 0%, rgba(45, 45, 45, 0) 32.81%, rgba(45, 45, 45, 0) 62.5%, #2D2D2D 100%);
  position: absolute;
}

.placeholder {
  width: calc(12vh - 4px);
  height: calc(12vh - 4px);
  border: 4px solid white;
  border-radius: 50%;
  margin: 2px auto 0;
}

.animations {
  display: flex;
  max-width: 375px;
  min-height: 93px;
  margin: 0 auto;
}

.back {
  position: absolute;
  width: 40px;
  height: 40px;
  left: 10px;
  top: 10px;
  border-radius: 50%;
  background-color: rgba($mineShaft, .6);
  box-shadow: 0 1px 10px rgba(183, 181, 170, .4);
  background-repeat: no-repeat;
  background-size: 24px 13px;
  background-position: center;
  background-image: image-set("images/back.webp" 1x, "images/back@2x.webp" 2x, "images/back@3x.webp" 3x);
  z-index: 10;
}


.lottie_wrap {
  position: relative;
  margin: 0 auto;
  height: fit-content;
  width: 125px;

  span {
    position: absolute;
    font-family: $fontFamily;
    font-size: $fontSize40;
    font-weight: 700;
    line-height: $fontSize32;
    letter-spacing: -0.5673684477806091px;
    text-align: center;
    color: $white;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.shape {
  background-color: #C56554;
  background-repeat: no-repeat;
  width: 67%;
  height: 57%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  -webkit-box-shadow: 0px -1px 5px 500px rgba(#2D2D2D, 0.5);
  -moz-box-shadow: 0px -1px 5px 500px rgba(#2D2D2D, 0.5);
  box-shadow: 0px -1px 5px 500px rgba(#2D2D2D, 0.5);
  border: 5px solid #C56554;
  background-color: transparent;
  background-image: none;
  top: 20%;
}

.full_timer {
  width: fit-content;
  position: relative;
  margin-left: auto;

  span {
    position: absolute;
    font-family: $fontFamily;
    color: $white;
    font-size: $fontSize18;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.567px;
    text-align: center;
    left: calc(50% - 9.5px);
    top: calc(50% - 18px);
  }

  &.two_digit {
    span {
      left: calc(50% - 15.5px);
    }
  }
}

.heart {
  width: 125px;
  margin-right: auto;
}

.face_detection {
  font-family: $fontFamily;
  font-size: $fontSize14;
  font-weight: 400;
  line-height: $fontSize20;
  letter-spacing: 0px;
  text-align: center;
  color: $white;
  margin: 10px;

  b {
    font-weight: 700;
    color: #EE8F61;
  }
}

.main {
  width: 100%;
  flex-basis: 75%;
  overflow: hidden;
  position: relative;

  &.full_screen {
    flex-basis: 100%;

    .face_instruction {
      position: absolute;
      opacity: 1;
      max-width: 315px;
      bottom: 15%;
      left: 50%;
      z-index: 1;
      transform: translateX(-50%);
      color: $white;
      width: 100%;
      text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.20);
      text-align: center;
      font-family: $fontFamily;
      font-size: $fontSize14;
      font-style: normal;
      font-weight: 400;
      line-height: $fontSize20;

      .highlighted {
        animation: fade 5s ease-in-out 1;
        animation-fill-mode: forwards;
        opacity: 1;
        font-weight: 400;
      }
    }
  }
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  flex-basis: 80%;
}

.video {
  display: none;
  position: absolute;
}

.rate_wrap {
  width: 125px;
}

.footer {
  display: flex;
  flex-basis: 20%;
  flex-grow: 1;
  justify-content: space-evenly;
  width: 100%;
  flex-direction: column;
  opacity: 0.9;

  &.full_screen {
    display: none;
  }

  .instruction {
    color: $white;
    text-align: center;
    margin: 10px;
    font-size: $fontSize14;
    font-style: normal;
    font-weight: 400;
    line-height: $fontSize20;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  }

  .button_container {
    margin: 0 auto 20px;
    width: 12vh;
    height: 12vh;
    border-radius: 50%;
    margin-top: auto;
    border: 5px solid #fff;
    display: flex;
    padding: 0;

    .button {
      display: block;
      width: calc(100% - 8px);
      height: calc(100% - 8px);
      margin: auto;
      background: var(--primaryColor);
      border-radius: 50%;
      border: 4px solid transparent;
    }
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}