$maxButtonWidth: 325px;
$maxWidthDefault: 430px;
$maxWidthFull: 450px;
$minHeightDefault: 668px;
$desktopWidth: 1000px;

$pageTransitionDelay: 200ms;
$pageTransitionDuration: 400ms;

// font-size
$fontSizeHtml: 100%; // 62.5% of 16px = 10px
$fontSize9: rem(9); // 9px
$fontSize10: rem(10); // 10px
$fontSize11: rem(11); // 11px
$fontSize12: rem(12); // 12px
$fontSize13: rem(13); // 13px
$fontSize14: rem(14); // 14px
$fontSize15: rem(15); // 15px
$fontSize16: rem(16); // 16px
$fontSize17: rem(17); // 17px
$fontSize18: rem(18); // 18px
$fontSize20: rem(20); // 20px
$fontSize21: rem(21); // 21px
$fontSize22: rem(22); // 22px
$fontSize23: rem(23); // 23px
$fontSize24: rem(24); // 24px
$fontSize26: rem(26); // 26px
$fontSize28: rem(28); // 28px
$fontSize30: rem(30); // 30px
$fontSize32: rem(32); // 32px
$fontSize34: rem(34); // 34px
$fontSize36: rem(36); // 36px
$fontSize38: rem(38); // 38px
$fontSize40: rem(40); // 40px
$fontSize42: rem(42); // 42px
$fontSize44: rem(44); // 44px
$fontSize48: rem(48); // 48px
$fontSize50: rem(50); // 50px
$fontSize55: rem(55); // 55px

$fontSizeDefault: $fontSize16;

$fwn: 400; // font weight normal
$fwsb: 500; // font semi bold
$fwb: 600; // font weight bold
$fwstb: 700; // font weight bold
$fwexb: 800; // font weight extra bold