@import 'src/styles';

.header {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 15px;

  .title {
    color: var(--primaryColor);
    font-weight: 600;
    font-size: $fontSize15;
    line-height: $fontSize20;

  }

  .subtitle {
    font-size: $fontSize12;
    line-height: $fontSize16;
    color: #818181;
  }

  .subtitleCentral {
    font-weight: 600;
    font-size: $fontSize18;
    line-height: $fontSize26;
    text-align: center;
  }
}