@import "src/styles";

.wrapper {
  margin-top: -60px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  .footer {
    position: absolute;
    width: 100%;
    height: 170px;
    left: 0;
    bottom: 0;
    background: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &.full_screen {
      background: transparent;

      .back {
        color: $white;
        text-align: center;
        font-family: $fontFamily;
        font-size: $fontSize18;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.464px;
      }
    }

    .back {
      margin-top: 10px;
      text-decoration: none;
    }
  }
}