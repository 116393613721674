@import 'src/styles';

.overlay {
  position: fixed;
  z-index: 2;
  inset: 0;
  backdrop-filter: blur(3.5px);
  background: $manageOverlayBg;

  .modal {
    background: $manageModalBg;
    border-radius: 40px 40px 0 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 25px;

    .close {
      position: absolute;
      right: 20px;
      top: 20px;

      svg {
        path {
          fill: $manageModalCloseIcon;
        }
      }
    }

    .title {
      font-family: $managePrimaryFontFamily;
      font-style: normal;
      font-weight: 700;
      font-size: $fontSize28;
      line-height: $fontSize32;
      margin-bottom: 10px;
      white-space: pre-line;
    }

    .subtitle {
      font-family: $manageSecondaryFontFamily;
      font-style: normal;
      font-weight: 400;
      font-size: $fontSize15;
      line-height: $fontSize22;
      color: $manageSubtitleColor;
      margin-bottom: 30px;
    }

    .textarea {
      width: 100%;
      height: 110px;
      margin-bottom: 50px;
      padding: 8px 10px;
      font-size: $fontSize15;
      line-height: $fontSize22;
      border: 0.5px solid $manageTextareaBorder;
      outline: none;
      border-radius: 7px;
      color: $manageMainColor;
      caret-color: $inputCaretColor;
      background: $manageTextareaBg;
    }

    .button {
      border-radius: 34px;
      display: flex;
      align-items: center;
      justify-content: center;

      .button__title {
        font-family: $fontFamilyPhilosopher;
        font-style: normal;
        font-weight: 700;
        letter-spacing: -0.57px;
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
        font-size: $fontSize22;
        line-height: $fontSize26;
        color: $white;
      }
    }
  }
}