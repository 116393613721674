@import "src/styles";

.feature {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  margin-left: 15px;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  .dot {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: var(--primaryColor);
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      width: 15px;
      height: 15px;
      background-image: url(./check.svg);
    }
  }

  .text {
    font-size: $fontSize15;
    font-weight: $fwn;
    width: 80%;
    color: $plansFeatureTextColor;
  }
}