@import "src/styles/index";

.wrapper {
  margin-top: 15px;
  z-index: 1;
  position: relative;
  width: 80%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-align: center;

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: $fontSize20;
    line-height: $fontSize28;
    opacity: 0;
    position: absolute;
    top: 0;
    color: var(--primaryText);

    &.active {
      animation: fadeInTitle ease 2s;
      opacity: 1;
    }
  }
}

@keyframes fadeInTitle {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}