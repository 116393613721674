@import 'src/styles';

.wrap {
  padding: 5vh 20px 5vh;
  margin: 0 auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: $manageBg;

  .close {
    position: absolute;
    right: 14px;
    top: 20px;

    svg {
      path {
        fill: $manageModalCloseIcon;
      }
    }
  }

  .title {
    font-family: $fontFamilyPhilosopher;
    font-style: normal;
    font-weight: 700;
    font-size: $fontSize32;
    line-height: $fontSize34;
    margin-bottom: 15px;
  }

  .text {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: $fontSize16;
    line-height: $fontSize22;
    color: $manageMainColor;
    letter-spacing: normal;

    b {
      font-weight: 600;
      color: $manageModalCloseIcon;
    }
  }

  .dark {
    color: $manageDarkColor;
  }

  .bottom_button {
    font-size: $fontSize22;
    line-height: $fontSize24;
  }

  .footnote {
    color: $manageDarkColor;
    text-align: center;
    font-family: $fontFamily;
    font-size: $fontSize12;
    font-weight: 400;
    line-height: normal;

    .email {
      font-weight: 600;
      color: $manageTextareaBorder;
    }
  }
}