@import "src/styles";

.progressbar-enter {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
}

.progressbar-enter-active {
  opacity: 1;
  max-height: 100px;
  transition: all $pageTransitionDuration ease;
  transition-delay: $pageTransitionDelay;
}

.progressbar-exit {
  opacity: 1;
  max-height: 100px;
}

.progressbar-exit-active {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all $pageTransitionDuration ease;
}
