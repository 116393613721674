@import "src/styles";

.bg_wrap {
  background: $manageBg;
}

.wrapper {
  max-width: 400px;
  margin: 0 auto;
  position: relative;
}
