:root {
  --primaryColor: #ee8f61;/* Main */
  --primaryColorLight: #ee8f6175; /* Tint */
  --secondaryColor: #16786c; /* Accent */
  --secondaryColorLight: #16786c75;
  --primaryText: #2d2d2d;  /* Font */
  --mainBg: #f7f7f7;  /* Background */
  --primaryTextLight: #2d2d2d40;
  --titleText: #6a52ff;
  --questionHighlightTitleColor: var(--primaryColor);
  --answerTitleTextAlign: center;
  --secondaryText: white;
  --secondaryBg: white; /* Quetions */
  --feedbackStarColor: var(--primaryColor);
  --buttonBackground: var(--primaryColor);
  --injectedPaymentPrimaryColor: var(--buttonBackground);
  --titleH2Color: var(--primaryText);
  --progressTextLight: #2d2d2d99;

  --payModalPrimaryColor: var(--primaryColor);
}