@import 'src/styles';

.wrapper {
  width: 100%;
  background-image: image-set('images/bg.webp' 1x, 'images/bg@2x.webp' 2x, 'images/bg@3x.webp' 3x);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: -30px;
  margin-top: -60px;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: -60px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color:rgba(0, 0, 0, .3);
  }

  .header {
    background: #F7F7F7;
    height: 45px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 25px;

    &__title {
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 400;
      font-size: $fontSize16;
      line-height: $fontSize22;
      text-align: center;
      letter-spacing: -0.5px;
      color: $mineShaft;
    }
  }
}
