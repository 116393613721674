@import "src/styles";

.shape {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("icons/scan-border.svg") no-repeat center center;
  background-size: 100% 100%;
  transition-property: all;
  transition-duration: 100ms;
  width: 60%;
  height: 60%;
  left: 20%;
  top: 20%;
}
